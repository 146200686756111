<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 发票管理 </template>
      <template #input>
        <div class="selects">
          <a-input
            style="margin-right: 20px"
            v-model="mobile"
            placeholder="请输入手机号"
          />
          <a-input
            style="margin-right: 20px"
            v-model="name"
            placeholder="请输入姓名"
          />
          <a-input
            style="margin-right: 20px"
            v-model="subOrderCode"
            placeholder="请输入订单编号"
          />
          <a-input v-model="title" placeholder="请输入发票抬头" />
          <a-range-picker
            @change="findtime"
            v-model="time"
            class="datePicker"
          />
          <!-- 商品类型 -->
          <a-select class="input" 
            placeholder="请选择商品类型"
            style="width: 120px"
            @change="shopTypehandleChange"
            v-model="productTxt"
          >
            <a-icon
              slot="suffixIcon"
              type="caret-down"
              style="color: #3681ef"
            />
            <a-select-option
              v-for="(item, index) in productType"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <!-- 抬头类型 -->
          <a-select class="input" 
            placeholder="抬头类型"
            style="width: 120px"
            @change="handleChange"
            v-model="typeTxt"
          >
            <a-icon
              slot="suffixIcon"
              type="caret-down"
              style="color: #3681ef"
            />
            <a-select-option :value="1"> 企业 </a-select-option>
            <a-select-option :value="3"> 个人/非企业单位 </a-select-option>
          </a-select>

          <a-button type="primary" :loading="loading" @click="search()" v-hasPermi="['invoice:list:query']"
            >搜索</a-button
          >
          <a-button class="all_btn_border btnBorder" @click="reset()"
            >重置</a-button
          >
        </div>
        
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs :default-active-key="null" @change="callback">
        <a-tab-pane :key="null" tab="全部"></a-tab-pane>
        <a-tab-pane :key="1" tab="开票中"></a-tab-pane>
        <a-tab-pane :key="2" tab="已开票"></a-tab-pane>
        <a-tab-pane :key="3" tab="已作废"></a-tab-pane>
      </a-tabs>
      <a-table
        class="table-template"
        :columns="columns"
        :scroll="{ x: true }"
        :data-source="tableData"
        :rowKey="(item) => item.invoiceRecordId"
        :loading="loading"
        @change="onPage"
        :pagination="{
          hideOnSinglePage: true,
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, index">
          {{ (pageNumber - 1) * pageSize + index + 1 }}
          <!-- {{ index + 1 }} -->
        </template>
        <template slot="phone" slot-scope="text, item">
          {{ item.phone ? $encryption('empty','phone',item.phone) : "--" }}
        </template>
        <template slot="operation" slot-scope="text, record">
          <div class="btn_router_link">
            <a @click="godedetails(record.invoiceRecordId)" v-hasPermi="['invoice:detail:query']">详情</a>
            <span v-if="record.pdf != null">|</span>
            <a v-if="record.pdf != null" @click="goinvoice(record.pdf)"
              >查看发票</a
            >
          </div>
        </template>
      </a-table>
    </div>
    <!-- 审核/详情弹框 -->
    <a-modal
      title=""
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="hide()"> 隐藏 </a-button>
        <a-button key="submit" type="primary" @click="handleOk">
          公开
        </a-button>
      </template>
      <div class="userNameAusor">
        <img src="@/assets/image/Backlogin.png" alt="" />
        <p>王熬过</p>
      </div>
      <div class="classMessage">
        <img src="@/assets/image/Backlogin.png" alt="" />
        <div class="message">
          <p class="title">孤独症课程</p>
          <p class="time">第一期：2021/12/23-2021/12/30</p>
        </div>
      </div>
      <p class="messItem">评价：好评</p>
      <p class="messItem">点评：这个老师讲课特别精彩</p>
      <p class="messItem">审核状态：隐藏</p>
      <p class="messItem">回复：感谢亲的支持～</p>
      <div class="textArea">
        <p>回复：</p>
        <a-textarea
          v-model="reply"
          placeholder=""
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    fixed: "left",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "发票号",
    align: "center",
    dataIndex: "fphm",
  },
  {
    title: "订单编号",
    align: "center",
    dataIndex: "subOrderCode",
  },
  {
    title: "申请时间",
    align: "center",
    dataIndex: "createTime",
  },
  {
    title: "支付交易单号",
    align: "center",
    dataIndex: "tradeId",
  },
  {
    title: "用户姓名",
    align: "center",
    dataIndex: "userName",
  },
  {
    title: "手机号码",
    align: "center",
    dataIndex: "phone",
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "商品类型",
    align: "center",
    dataIndex: "productTypeTxt",
  },
  {
    title: "开票金额",
    align: "center",
    dataIndex: "price",
  },
  {
    title: "发票抬头",
    align: "center",
    dataIndex: "title",
  },
  {
    title: "抬头类型",
    align: "center",
    dataIndex: "typeTxt",
  },
  {
    title: "开票状态",
    align: "center",
    dataIndex: "statusTxt",
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      loading: false,
      reply: "", // 回复
      columns,
      tableData: [], // 列表数据
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      visible: false, // 弹框状态
      confirmLoading: false, // 按钮状态
      // 商品类型
      productType: [
        {
          id: 1,
          name: "课程",
        },
        {
          id: 2,
          name: "图书",
        },
        {
          id: 3,
          name: "教具",
        },
        {
          id: 4,
          name: "模拟考试",
        },
        {
          id: 5,
          name: "电子照片",
        },
        {
          id: 6,
          name: "延期",
        },
        {
          id: 7,
          name: "补考 ",
        },
        {
          id: 8,
          name: "直播 ",
        },
      ],
      typeTxt: undefined, // 发票类型
      keyword: null, // 关键字

      mobile: null, // 关键字（手机号）
      name: null, // 关键字（姓名）
      subOrderCode: null, // 	关键字（订单编号）
      title: null, // 关键字（发票抬头）
      productTxt: undefined, // 商品类型
      time: [],
      status: null, //开票状态
    };
  },
  // 事件处理器
  methods: {
    // 去详情
    godedetails(e) {
      this.$router.push("/admin/OrderConfig/InvoiceDetail?id=" + e);
    },
    // 打开新页面看发票
    goinvoice(e) {
      window.open(e);
    },
    // 隐藏
    hide() {
      this.$confirm({
        title: "隐藏",
        content:
          "您隐藏该用户的评价后，用的评价将不被其他人看见，您的回复与用户的评价仅其本人可见。",
        onOk() {
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {},
      });
    },
    // tab切换
    callback(e) {
      this.status = e;
      this.pageNumber = 1;
      this.getData();
    },
    findtime(time, timeString) {
      // console.log(time, timeString);
      this.time = timeString;
    },
    // 抬头类型下拉
    handleChange(e) {
      // console.log(e);
    },
    // 商品类型
    shopTypehandleChange(e) {
      // console.log(e);
    },
    showModal(e) {
      // console.log(e);
      this.visible = true;
    },
    handleOk(e) {
      this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel(e) {
      // console.log("Clicked cancel button");
      this.visible = false;
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getData();
    },
    // 重置
    reset(e) {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.typeTxt = undefined; // 发票类型
      this.mobile = null; // 关键字
      this.name = null; // 关键字
      this.subOrderCode = null; // 关键字
      this.title = null; // 关键字
      this.productTxt = undefined; // 商品类型
      this.time = [];
      this.getData();
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      this.getData();
    },
    // 获取数据
    getData() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/invoice/",
        method: "get",
        params: {
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
          type: this.typeTxt,
          productType: this.productTxt,
          startTime: this.time[0],
          endTime: this.time[1],
          status: this.status,
          mobile: this.mobile,
          name: this.name,
          subOrderCode: this.subOrderCode,
          title: this.title,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getData();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.datePicker::before {
  content: "申请时间：";
  position: absolute;
  left: -60px;
  top: 0;
  font-size: 12px;
  color: #000000;
  line-height: 30px;
}
.textArea {
  display: flex;
  margin-top: 20px;
  p {
    width: 50px;
  }
}
.messItem {
  padding-top: 20px;
}
.classMessage {
  margin-top: 24px;
  display: flex;
  img {
    width: 150px;
    height: 100px;
  }
  .message {
    padding-left: 20px;
    .title {
      font-size: 20px;
      font-weight: bold;
    }
    .time {
      margin-top: 30px;
    }
  }
}
.userNameAusor {
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #ccc;
  }
  p {
    margin-left: 10px;
  }
}
// .findItem {
//   padding-bottom: 24px;
//   display: flex;
//   align-items: center;
  // /deep/.ant-select {
  //   width: 193px !important;
  //   height: 29px !important;
  //   margin-right: 0 !important;
  //   margin-left: 24px !important;
  //   font-size: 12px !important;
  //   color: #999999 !important;
  //   line-height: 14px !important;
  //   margin: 0 !important;
  //   margin-right: 24px !important;
  // }
  // /deep/.ant-calendar-picker {
  //   width: 240px !important;
  //   height: 29px !important;
  //   font-size: 12px !important;
  //   color: #999999 !important;
  //   line-height: 14px !important;
  //   margin-bottom: 0 !important;
  // }
//   /deep/.ant-input {
//     width: 240px !important;
//     height: 29px !important;
//     font-size: 12px !important;
//     color: #999999 !important;
//     line-height: 14px !important;
//     margin-bottom: 0 !important;
//   }
//   .ant-btn {
//     margin-left: 24px;
//   }
// }
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
/deep/.ant-modal-footer {
  text-align: center;
  border: 0;
}
.selects {
  margin-top: 16px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  .input{
    width: 240px;
    margin-bottom: 20px;
    margin-right: 30px;
  }
  /deep/.ant-select {
    width: 193px !important;
    height: 29px !important;
    margin-right: 0 !important;
    margin-left: 24px !important;
    color: #999999 !important;
    line-height: 14px !important;
    margin: 0 !important;
    margin-right: 24px !important;
  }
  /deep/.ant-calendar-picker {
    width: 240px !important;
    height: 29px !important;
    font-size: 12px !important;
    color: #999999 !important;
    line-height: 14px !important;
    margin-bottom: 0 !important;
    margin-left: 60px !important;
  }
  .ant-btn {
    margin-left: 24px;
  }
}
</style>
